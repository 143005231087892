import React from "react"
import { graphql } from "gatsby"
import Prijs from "gatsby-theme-nieuwbouw/src/components/Prijs";
import {Bouwnummer} from "gatsby-theme-nieuwbouw/src/graphqlTypes";

export const columns = [
  {
    name: 'Woonoppervlak',
    format: (item: any) => <span>{item.algemeen.woonoppervlakte} m<sup>2</sup></span>,
  },
  {
    name: 'Inhoud',
    format: (item: any) => <span>{item.algemeen.inhoud} m<sup>3</sup></span>,
  },
  {
    name: 'Perceel',
    format: (item: any) => <span>{item.detail.kadaster[0].kadastergegevens.oppervlakte} m<sup>2</sup></span>,
  },
  {
    name: 'Prijs',
    format: (bouwnummer: Bouwnummer) => (bouwnummer.financieel.overdracht.status === 'BESCHIKBAAR' && bouwnummer.financieel.overdracht.koopprijs) && <Prijs prijs={bouwnummer.financieel.overdracht.koopprijs} />,
  },
];

interface Props {
  bouwnummer: Bouwnummer
}

export default ({ bouwnummer }: Props) => {
  return (
    <>
      {columns.map((column, i) => (
        <div key={column.name} className={"py-2"}>
            <span className={"font-weight-bold"}>{column.name}: </span>
            {column.format(bouwnummer)}
        </div>
      )
      )}
    </>
  )
}

export const query = graphql`
  fragment BouwnummerData on Bouwnummer {
    algemeen {
      woonoppervlakte
      inhoud
    }
    detail {
      kadaster {
        kadastergegevens {
          oppervlakte
        }
      }
    }
    financieel {
      overdracht {
        koopprijs
      }
    }
  }
`