import React from 'react'
import {graphql, Link} from "gatsby"
import {Modal, Button, Container, Row, Col } from 'react-bootstrap'
import Data from "gatsby-theme-nieuwbouw/src/components/nummer/Data"
import {woonhuistypeMap} from "gatsby-theme-nieuwbouw/src/utils/utils"
import Plattegrond from "gatsby-theme-nieuwbouw/src/components/media/Plattegrond"
import Carousel from "gatsby-theme-nieuwbouw/src/components/media/Carousel"
import {Bouwnummer} from "gatsby-theme-nieuwbouw/src/graphqlTypes";
import { GatsbyImage } from "gatsby-plugin-image";

interface Props {
  bouwnummer: Bouwnummer;
  onRequestClose: any;
  isOpen: boolean;
}

export default ({ bouwnummer, onRequestClose, isOpen }: Props) => {
  const { diversen, bouwtype, hoofdfoto, plattegronden } = bouwnummer

  return (
    <Modal show={isOpen} onHide={onRequestClose} animation={true} size={"xl"}>
      <Modal.Header closeButton>
        <Modal.Title>
          Bouwnummer {diversen.diversen.bouwnummer}
        </Modal.Title>
      </Modal.Header>
      {hoofdfoto?.localFile?.childImageSharp &&
      <GatsbyImage
          alt={"artist impression"}
          image={hoofdfoto.localFile.childImageSharp.gatsbyImageData} />
      }
      <Modal.Body>
        <section className="py-5">
            <Row>
              <Col md={6} className="pb-md-0">
                <h2>
                  Woningtype {bouwtype.algemeen.omschrijving}
                  {bouwtype.algemeen.woonhuistype &&
                  <small> {woonhuistypeMap[bouwtype.algemeen.woonhuistype]}</small>
                  }
                </h2>
              </Col>
              <Col md={{span: 5, offset: 1}} className="pb-md-0">
                <h5 className={"font-weight-bold"}>Specificaties:</h5>
                <Data bouwnummer={bouwnummer} />
              </Col>
            </Row>
        </section>

        <Container fluid="xl" className="py-5">
          <Row className={'text-center'}>
            {plattegronden && plattegronden.length > 0 && plattegronden.map((plattegrond: any) =>
                <Col lg={6} key={plattegrond.id}>
                  <Plattegrond image={plattegrond} />
                </Col>
            )}
          </Row>
        </Container>
        <section className={`pb-3 px-3`}>
            {bouwnummer.fotos &&
            <Carousel images={bouwnummer.fotos} />
            }
        </section>
        <div className={"text-center w-100 my-5"}>
          <Button variant={"primary"} as={Link} to={`/contact`} className={"mx-auto"}>Neem contact op</Button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-dark" onClick={onRequestClose}>
          Sluiten
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export const query = graphql`
  fragment BouwnummerModal on Bouwnummer {
  ...BouwnummerData
  fotos {
    ...Image
  }
  hoofdfoto {
    id
    localFile {
      childImageSharp {
        gatsbyImageData(transformOptions: {cropFocus: CENTER}, layout: FULL_WIDTH)
      }
    }
    titel
    volgnummer
  }
  bouwtype {
    children {
      id
      ... on MarkdownRemark {
        html
        frontmatter {
          description
        }
      }
    }
    algemeen {
      omschrijving
      woonhuistype
    }
    diversen {
      objectcode
    }
  }
  diversen {
    diversen {
      bouwnummer
    }
  }
  financieel {
    overdracht {
      status
      koopprijs
    }
  }
  plattegronden {
    ...Plattegrond
  }
}
`